import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { Col, Row, Container, Button, Tab, Tabs } from "react-bootstrap";
import "./Myproduct.scss";

import payer from "../image/payar.jpg";
import chilly from "../image/chilly thejas.jpg";
import churakka from "../image/churakka.jpg";
import cheeni from "../image/cheeni amara.jpg";
import athulya from "../image/chilly athulya.jpg";
import kumbalam from "../image/kumbalam.jpg";
import ladies from "../image/ladies finger.jpg";
import mathan from "../image/mathan.jpg";
import paval from "../image/paval.jpg";
import vazhuthana from "../image/vazhuthana.jpg";
import padavalam from "../image/padavalam.jpg";
import jone from "../image/jone.jpg";
import jtwo from "../image/jtwo.jpg";
import jfour from "../image/jfour.jpg";
import jthree from "../image/jthree.jpg";
import plant from "../image/plant.jpg";






// Define the products based on categories
const categories = {
  vegetable: [
    {
      image: payer,
      name: "Baby Truck",
      rating: 4.5,
      price: 676,
      isNew: true,
    },
    {
      image: chilly,
      name: "Rocket",
      rating: 3.5,
      price: 349,
      isNew: true,
    },
    {
      image: churakka,
      name: "Toy Car",
      rating: 4,
      price: 199,
      isNew: true,
    },
	{
		image: kumbalam,
		name: "Plush Bear",
		rating: 4.6,
		price: 249,
		isNew: true,
	  },
	  {
		image: ladies,
		name: "Dollhouse",
		rating: 4.4,
		price: 899,
		isNew: true,
	  },
	  {
		image: cheeni,
		name: "Superhero Action Figure",
		rating: 4.2,
		price: 499,
		isNew: true,
	  },
	  {
		image: athulya,
		name: "Building Blocks",
		rating: 4.8,
		price: 149,
		isNew: true,
	  },





	  {
		image: mathan,
		name: "Superhero Action Figure",
		rating: 4.2,
		price: 499,
		isNew: true,
	  },
	  {
		image: paval,
		name: "Building Blocks",
		rating: 4.8,
		price: 149,
		isNew: true,
	  },





	  {
		image: vazhuthana,
		name: "Superhero Action Figure",
		rating: 4.2,
		price: 499,
		isNew: true,
	  },
	  {
		image: padavalam,
		name: "Building Blocks",
		rating: 4.8,
		price: 149,
		isNew: true,
	  },
  ],
  juice: [
    {
      image: jone,
      name: "Superhero Action Figure",
      rating: 4.2,
      price: 499,
      isNew: true,
    },
    {
      image: jtwo,
      name: "Building Blocks",
      rating: 4.8,
      price: 149,
      isNew: true,
    },
	{
		image: jthree,
		name: "Building Blocks",
		rating: 4.8,
		price: 149,
		isNew: true,
	  },
	  {
		image: jfour,
		name: "Building Blocks",
		rating: 4.8,
		price: 149,
		isNew: true,
	  },
  ],


  plants: [
    {
      image: plant,
      name: "Superhero Action Figure",
      rating: 4.2,
      price: 499,
      isNew: true,
    },
    
  ],
 
};

const Product = ({ product }) => {
  const { image, name, price, rating } = product;
  return (
    <Col xs={6} md={4} lg={3} className="mt-4">
      <div className="ezy__epgrid12-item p-1 pb-0 ">
        <div className="position-relative">
          {/* <div className="ezy__epgrid12-fav-icon">
            <FontAwesomeIcon icon={faHeart} />
          </div> */}
          <img src={image} alt={name} className="imagestylevesd" />
        </div>
        <div className="ezy__epgrid12-content">
          {/* <div className="d-flex justify-content-between align-items-center">
            <div>
              <h6 className="mb-1">{name}</h6>
              <span className="ezy__epgrid12-rating">
                {Array.from({ length: Math.floor(rating) }, (_, index) => (
                  <FontAwesomeIcon key={index} icon={faStar} className="me-1" />
                ))}
                {rating % 1 !== 0 && (
                  <FontAwesomeIcon icon={faStarHalfAlt} className="me-1" />
                )}
              </span>
            </div>
            <p className="mb-0 fs-3 fw-bold">${price}</p>
          </div> */}
        </div>
      </div>
    </Col>
  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
};

const Myproduct = () => {
  const [activeTab, setActiveTab] = useState("vegetable");

  return (
    <section className="ezy__epgrid12 gray home">
      <Container>
        <Row className="justify-content-center">
          <Col lg={9}>
            <Tabs
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
              className="mb-3"
            >
              <Tab eventKey="vegetable" title="Vegetable">
                <Row>
                  {categories?.vegetable.map((product, index) => (
                    <Product key={index} product={product} />
                  ))}
                </Row>
              </Tab>
              <Tab eventKey="juice" title="Lotion">
                <Row>
                  {categories?.juice.map((product, index) => (
                    <Product key={index} product={product} />
                  ))}
                </Row>
              </Tab>

              <Tab eventKey="Plants" title="Plants">
                <Row>
                  {categories?.plants.map((product, index) => (
                    <Product key={index} product={product} />
                  ))}
                </Row>
              </Tab>
         
            </Tabs>

            {/* <Col className="mt-5">
              <Row className="justify-content-center">
                <Col md={5}>
                  <Button
                    variant=""
                    className="ezy__epgrid12-btn w-100 fw-bold"
                  >
                    Load More
                  </Button>
                </Col>
              </Row>
            </Col> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Myproduct;
